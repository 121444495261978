import React, {Component} from 'react';
import {compose} from "redux";
import classnames from "classnames";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import NavigationMenu from './components/NavigationMenu';
import AccountPopup from './components/AccountPopup';
import Scrollbar from "../Scrollbar";
import {routes} from "../../constants/AppConstants";
import {signOut} from "../../containers/App/actions";

import './css/side-menu.css';

class SideMenu extends Component {

    static propTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            isHover: false,
            isOpenAccountPopup: false
        };

        this.accountContainerRef = React.createRef();
    }

    redirectToDashboard = () => {
        const {history} = this.props;
        history.push("/");
    }

    onHover = () => {
        this.setState({
            isHover: true
        })
    }

    onLeave = () => {
        this.setState({
            isHover: false,
            isOpenAccountPopup: false
        })
    }

    togglePopup = () => {
        this.setState((state, props) => {
            return {
                isOpenAccountPopup: !state.isOpenAccountPopup
            }
        });
    }

    closePopup = () => {
        this.setState((state, props) => {
            return {
                isOpenAccountPopup: false
            }
        });
    }

    onLogout = () => {
        const {signOut} = this.props;
        signOut();
        window.location = '/auth';
    };

    render() {
        const {healthServices, isAdmin, email, userName} = this.props;
        const {isHover, isOpenAccountPopup} = this.state;
        const accountContainerStyle = classnames("side-menu__account-container", {"account-popup-open": isOpenAccountPopup});

        return (
            <div className="side-menu__container" onMouseOut={this.onHover} onMouseLeave={this.onLeave}>
                <div className="side-menu__title">
                    <div className="side-menu__kraken-icon" onClick={() => this.redirectToDashboard()}/>
                    <div className="side-menu__title-name" onClick={() => this.redirectToDashboard()}/>
                </div>
                <Scrollbar height="calc(100% - 144px)">
                    <NavigationMenu routes={routes} isAdmin={isAdmin} allHealth={healthServices} isHover={isHover}/>
                </Scrollbar>
                <div className={accountContainerStyle} onClick={this.togglePopup} ref={this.accountContainerRef}>
                    <div className="account-icon"/>
                    <span>Account</span>
                    <div className="account-button"/>
                    <AccountPopup isOpen={isOpenAccountPopup} accountContainerRef={this.accountContainerRef} closePopup={this.closePopup} email={email} userName={userName} onLogout={this.onLogout}/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        healthServices: state.app.healthServices,
        isAdmin: state.app.isAdmin,
        email: state.app.email,
        userName: state.app.userName
    }
}

function mapDispatchToProps(dispatch) {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SideMenu);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import includes from 'lodash/includes';
import map from 'lodash/map';
import NavigationItem from "../NavigationItem";

class NavigationMenu extends Component {

    state = {
        activeKey: '',
    }

    componentDidMount() {
        const {location: {pathname}} = this.props;
        this.setActiveKey(pathname)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location: {pathname}} = this.props;
        const {activeKey: prevActiveKey} = prevState;
        if (pathname !== prevActiveKey) {
            this.setActiveKey(pathname);
        }
    }

    setActiveKey = activeKey => this.setState({activeKey});

    onNavigate = route => {
        const {history} = this.props;
        history.push(route);
        this.setActiveKey(route);
    };

    renderRoutes = (routes) => {
        const {activeKey} = this.state;
        const {isAdmin, isHover, allHealth} = this.props;

        return map(routes, item => {
            const {icon, name, isAdminRoute, healthName, route, permissionKey, routes, hoverIcon, isHasChildren, isLastDatabases} = item;
            const isActive = includes(activeKey, permissionKey);

            return (<React.Fragment key={route}>
                <NavigationItem icon={icon}
                                name={name}
                                route={route}
                                isActive={isActive}
                                hoverIcon={hoverIcon}
                                isAdmin={isAdmin}
                                isAdminRoute={isAdminRoute}
                                allHealth={allHealth}
                                isHasChildren={isHasChildren}
                                nestedRoutes={routes}
                                activeKey={activeKey}
                                onNavigate={this.onNavigate}
                                isHover={isHover}
                                healthName={healthName}
                                isLastDatabases={isLastDatabases}/>
            </React.Fragment>)
        })
    }

    render() {
        const {routes} = this.props;

        const renderRotes = this.renderRoutes(routes);

        return (
            <div className="navigation-menu__container">
                {renderRotes}
            </div>
        );
    }
}

export default withRouter(NavigationMenu);
import React, {Component} from 'react';
import {connect} from "react-redux";

import HealthMenu from "../HealthMenu";
import SubscribeCloseFilterPopup from "../Popup/components/SubscribeCloseFilterPopup";
import SuccessSetFilterPopup from "../Popup/components/SuccessSetFilterPopup";
import {closePopupRequest, showPopupRequest, getHealth} from "../../containers/App/actionsAsync";
import {updateSubscribeFilter} from "../../containers/SubscribeDecide/actionsAsync";

import './css/header.css';

class Header extends Component {

    static propTypes = {
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        //  для того, чтобы перерендерить textarea, после изменения closeFilter
        const {popup, closeFilter, getHealth, healthServiceName, healthServices} = this.props;
        if (closeFilter !== prevProps.closeFilter) {
            popup.open && this.showCloseFilterPopup();
        }

        if (!healthServices[healthServiceName]?.healthArray) {
            getHealth(healthServiceName);
        }
    }

    showSuccessSetFilterPopup = () => {
        const {showPopup, closePopup} = this.props;
        const getPopupComponent = () => {
            return <SuccessSetFilterPopup closePopup={closePopup}/>
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    showCloseFilterPopup = () => {
        const {showPopup, closeFilter, updateSubscribeFilter} = this.props;
        const getPopupComponent = (onApply, closePopup) => {
            return <SubscribeCloseFilterPopup closePopup={closePopup} value={closeFilter} onApply={handleApply} updateSubscribeFilter={updateSubscribeFilter}/>
        }

        const handleApply = () => {
            this.showSuccessSetFilterPopup();
        }

        const popup = {
            component: getPopupComponent,
            open: true,
        }
        showPopup(popup);
    }

    render() {
        const {headerElement, title, status, isSubscribeDecide, healthServiceName, healthServices} = this.props;

        const renderTitle = headerElement ? headerElement : title;
        const editIcon = isSubscribeDecide ? <span className="edit-icon" onClick={this.showCloseFilterPopup}/> : '';
        const health = healthServices[healthServiceName]?.healthArray;

        return (
            <div className="header__container">
                <div className="header__title">
                    <div className="header__title-name-container">
                        <div className="header__title-name">
                            <div className="title">
                                {renderTitle}{editIcon}
                                <HealthMenu status={status} health={health} popoverPosition="left"/>
                            </div>
                            <span id="last-update" className="last-update"/>
                        </div>
                        <div id="legend-container"/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        title: state.app.title,
        headerElement: state.app.headerElement,
        lastUpdate: state.app.lastUpdate,
        status: state.app.status,
        popup: state.app.popup,
        healthServices: state.app.healthServices,
        isMagnetSalesWarning: state.app.isMagnetSalesWarning,
        isMagnetVendorsWarning: state.app.isMagnetVendorsWarning,

        closeFilter: state.subscribeDecide.closeFilter,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showPopup: (popup) => dispatch(showPopupRequest(popup)),
        closePopup: () => dispatch(closePopupRequest()),
        updateSubscribeFilter: (closeFilter) => dispatch(updateSubscribeFilter(closeFilter)),
        getHealth: (service) => dispatch(getHealth(service))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)

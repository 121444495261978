import * as types from "./constants";

export function signOut() {
    return {
        type: types.SIGN_OUT
    };
}

export function requestAuth() {
    return {
        type: types.REQUEST_AUTH
    };
}

export function succeedAuth(token) {
    return {
        type: types.SUCCEED_AUTH,
        token
    };
}

export function setTitle(title, headerElement) {
    return {
        type: types.SET_TITLE,
        title, headerElement
    };
}

export function updateTitle(title) {
    return {
        type: types.UPDATE_TITLE,
        title
    };
}

export function succeedInitApp(response) {
    return {
        type: types.INIT_APP,
        response,
    };
}

export function succeedGetInfo(response) {
    return {
        type: types.SUCCEED_GET_USER_INFO,
        response,
    };
}

export function onBlockUi() {
    return {
        type: types.BLOCK_UI,
    };
}

export function onUnblockUi() {
    return {
        type: types.UNBLOCK_UI,
    };
}

export function onWaitUi() {
    return {
        type: types.WAIT_UI,
    };
}

export function onUnwaitUi() {
    return {
        type: types.UNWAIT_UI,
    };
}

export function setInitContainer(containerName) {
    return {
        type: types.SET_INIT_CONTAINER,
        containerName
    };
}

export function requestedGetHealth(service) {
    return {
        type: types.REQUEST_GET_HEALTH,
        service
    };
}

export function succeedGetHealth(healthService, service) {
    return {
        type: types.SUCCEED_GET_HEALTH,
        healthService, service
    };
}

export function showPopup(popup) {
    return {
        type: types.SHOW_POPUP,
        popup
    }
}

export function closePopup() {
    return {
        type: types.CLOSE_POPUP,
    }
}

export function requestExport() {
    return {
        type: types.REQUEST_EXPORT,
        blockUi: true,
    }
}

export function succeedExport(exportId) {
    return {
        type: types.SUCCEED_EXPORT,
        blockUi: false,
        showDownloadPopup: true,
        exportId
    }
}

export function setMagnetSalesHealthWarning() {
    return {
        type: types.SET_MAGNET_SALES_HEALTH_WARNING,
    }
}

export function setMagnetVendorsHealthWarning() {
    return {
        type: types.SET_MAGNET_VENDORS_HEALTH_WARNING,
    }
}

export function succeedIsMagnetFailed(isMagnetSalesHealthFailed, isMagnetVendorsHealthFailed) {
    return {
        type: types.SUCCEED_IS_MAGNET_FAILED,
        isMagnetSalesHealthFailed, isMagnetVendorsHealthFailed
    }
}
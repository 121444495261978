import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from  "@react-oauth/google"
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import store from "./store/store";
import {Provider} from "react-redux";

ReactDOM.render(
    <GoogleOAuthProvider clientId="770875976056-qm72cts2365jjjsb3q99jcu4fnuro359.apps.googleusercontent.com">
        <Provider store={store} >
            <App />
        </Provider>
    </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Component} from 'react';
import {connect} from "react-redux";
import {initApp} from "../../containers/App/actionsAsync";
import {getCookieByName, setCookie} from "../../helpers/cookieUtils";
import {GoogleLogin} from "@react-oauth/google";

class AuthPage extends Component {

    state = {
        id_token: "",
    };

    componentDidMount() {
        const {history} = this.props;
        const authToken = getCookieByName('auth');

        if (authToken) {
            history.push('/');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {id_token} = this.state;
        if (id_token) {
            this.responseGoogleSuccess(id_token);
        }
    }

    responseGoogleSuccess = (tokenId) => {
        const {initApp, history} = this.props;

        initApp(tokenId).then(data => {
            const {isError, token} = data;
            if (isError) {
               console.log("googleClient.google request error");
            } else if (token) {
                setCookie('auth', data.token);
                history.push('/');
            }
        })
    }


    render() {
        return <div className="google-login-button-container">
            <GoogleLogin
                onSuccess={credentialResponse => {
                    this.setState({
                        id_token: credentialResponse.credential
                    })
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                auto_select
                size="large"
            />
        </div>
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initApp: (tokenId) => dispatch(initApp(tokenId)),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(AuthPage);